import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'

import MUITabs from 'src/components/tabs/tabs'
import TabPanel from 'src/components/tab-panel/tab-panel'
import TeamTab from 'src/components/team-tab/team-tab'
import RoleTab from 'src/components/role-tab/role-tab'

const TeamTabs: React.FC = () => {
  const { t } = useTranslation()
  const [ value, setValue ] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <MUITabs
        value={value}
        labels={[ t('team'), t('roles') ]}
        handleChange={handleChange}
      />
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <TeamTab />
        </TabPanel>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={1}>
          <RoleTab />
        </TabPanel>
      </Grid>
    </>
  )
}

export default TeamTabs