import React from 'react'
import { FastField, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'

import * as styles from 'src/components/role-dialog/add-role-dialog.module.less'
import InputLabel from 'src/components/input-label/input-label'
import Input from 'src/components/input/input'
import FormErrorMessage from 'src/components/form-error-message/form-error-message'
import { addRoleInitialData } from 'src/utils/typedefs/role'

// TODO: handle saving of new role
const AddRoleDialog: React.FC = () => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('is required', { type: 'name' })),
    description: Yup.string().required(t('is required', { type: 'description' }))
  })

  return (
    <Formik
      initialValues={addRoleInitialData}
      validationSchema={validationSchema}
      onSubmit={values => {
        console.log(values)
      }}
    >
      {formikProps => (
        <Form translate="">
          <Grid container direction="column">
            <Grid container direction="column">
              <InputLabel className={styles.membershipLabel}>{t('name')}*</InputLabel>
              <FastField
                id="name"
                name="name"
                component={Input}
                outlined={true}
                disableUnderline={true}
                value={formikProps.values.name}
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
              />
              {formikProps.errors.name && formikProps.touched.name && (
                <FormErrorMessage message={formikProps.errors.name} />
              )}
              <InputLabel className={styles.membershipLabel}>{t('description')}*</InputLabel>

              {/* TODO: move textarea to Formik wrapped input */}
              <textarea
                name="description"
                rows={4}
                value={formikProps.values.description}
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
              />
              {formikProps.errors.description && formikProps.touched.description && (
                <FormErrorMessage message={formikProps.errors.description} />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default AddRoleDialog
