import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { PageMaxWidth } from 'src/components/util-components/util-components'
import Panel from 'src/components/panel/panel'
import Button from 'src/components/button/button'
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table'
import ExpansionList from 'src/components/expansion-list/expansion-list'
import CustomDialog from 'src/components/custom-dialog/custom-dialog'
import AddRoleDialog from 'src/components/role-dialog/add-role-dialog'
import Icon from 'src/components/icon/icon'
import TablePanelTitle from 'src/components/table-panel-title/table-panel-title'
import { allRoles, Role } from 'src/utils/typedefs/role'

const RoleTab: React.FC = () => {
  const { t } = useTranslation()
  const [openAddRole, setOpenAddRole] = React.useState(false)

  const handleAddRoleClick = () => {
    setOpenAddRole(true)
  }

  const handleAddRoleClose = () => {
    setOpenAddRole(false)
  }

  return (
    <PageMaxWidth>
      <Panel>
        <Grid container={true} justify="space-between">
          <TablePanelTitle title={t('roles and responsibilities')} />
          <Button onClick={handleAddRoleClick}>
            {<Icon name="sm-add" light={true} />} {t('add role')}
          </Button>
        </Grid>

        {/* TODO: refactor dialog in order to work with formik form */}
        {/* TODO: submit form is not working */}
        <CustomDialog
          open={openAddRole}
          onClose={handleAddRoleClose}
          title={t('add role')}
          content={<AddRoleDialog />}
          actions={
            <>
              <Button onClick={handleAddRoleClose}>{t('close')}</Button>
              <Button type="submit">{t('confirm')}</Button>
            </>
          }
        />

        <Grid item xs={12}>
          <RoleTable roles={allRoles} />
        </Grid>
      </Panel>
    </PageMaxWidth>
  )
}

interface RoleTableProps {
  roles: Role[]
}

const RoleTable: React.FC<RoleTableProps> = ({ roles }) => {
  const { t } = useTranslation()

  const cols: DataCols<Role> = useMemo(
    () => [
      { key: 'name', title: t('name'), getValue: role => t(role.name) },
      { key: 'status', title: t('status'), getValue: role => t(role.status) },
      { key: 'description', title: t('description'), getValue: role => t(role.description) }
    ],
    []
  )

  return (
    <>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <AdminTable columns={cols} data={roles}/>
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <ExpansionList columns={cols} data={roles} />
      </Box>
    </>
  )
}

export default RoleTab
