// extracted by mini-css-extract-plugin
export var bodyBase = "add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodyLarge = "add-role-dialog-module--body-large--gIXZd add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodyLargeBold = "add-role-dialog-module--body-large-bold--Wv1WR add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodyRegular = "add-role-dialog-module--body-regular--av5gq add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodyRegularBold = "add-role-dialog-module--body-regular-bold--enSTs add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodySmall = "add-role-dialog-module--body-small--UIPZP add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var bodySmallBold = "add-role-dialog-module--body-small-bold--aun-4 add-role-dialog-module--body-base--CmcyF add-role-dialog-module--site-font--h-nEg";
export var borderTop = "add-role-dialog-module--border-top--ujrUC";
export var breakWordContainer = "add-role-dialog-module--break-word-container--qTxfk";
export var buttonIconBase = "add-role-dialog-module--button-icon-base--KrITU";
export var clickLink = "add-role-dialog-module--click-link--2tzbR";
export var dropdownBase = "add-role-dialog-module--dropdown-base--hYhDV";
export var dropdownSelectBase = "add-role-dialog-module--dropdown-select-base--1-Pls add-role-dialog-module--text-input--dOWGG";
export var flexCol = "add-role-dialog-module--flex-col--27JDE";
export var formErrorMessage = "add-role-dialog-module--form-error-message--uBCxn";
export var h3 = "add-role-dialog-module--h3--2fwL4";
export var h4 = "add-role-dialog-module--h4--e1nSW";
export var hoverLink = "add-role-dialog-module--hover-link--+ZMYl";
export var hoverRow = "add-role-dialog-module--hover-row--YsvhW";
export var membershipContainer = "add-role-dialog-module--membership-container--qRpyb add-role-dialog-module--flex-col--27JDE add-role-dialog-module--primary-border--tiLwR";
export var membershipHeader = "add-role-dialog-module--membership-header--2wGrS";
export var membershipHeading = "add-role-dialog-module--membership-heading--+2mzR";
export var membershipLabel = "add-role-dialog-module--membership-label--Gf9Sg";
export var moreFiltersBorderClass = "add-role-dialog-module--more-filters-border-class--Stt1q";
export var pageBg = "add-role-dialog-module--page-bg--DV4XS";
export var pointer = "add-role-dialog-module--pointer--WI-u1";
export var primaryBorder = "add-role-dialog-module--primary-border--tiLwR";
export var shadowBoxLight = "add-role-dialog-module--shadow-box-light--RYQL9";
export var siteFont = "add-role-dialog-module--site-font--h-nEg";
export var slideDownAndFade = "add-role-dialog-module--slideDownAndFade--jj9M7";
export var slideLeftAndFade = "add-role-dialog-module--slideLeftAndFade--6CMDt";
export var slideRightAndFade = "add-role-dialog-module--slideRightAndFade--1ecCL";
export var slideUpAndFade = "add-role-dialog-module--slideUpAndFade--D8CHk";
export var statusDecoration = "add-role-dialog-module--status-decoration--OH6Tg";
export var textInput = "add-role-dialog-module--text-input--dOWGG";
export var textInverted = "add-role-dialog-module--text-inverted--sOLS7";
export var textMediumDark = "add-role-dialog-module--text-medium-dark--aBx4A";
export var tooltipFont = "add-role-dialog-module--tooltipFont--Q4G8h";
export var unstyledButton = "add-role-dialog-module--unstyled-button--qR6r7";