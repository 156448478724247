import React from 'react'

import Grid from '@material-ui/core/Grid'

import TeamTabs from 'src/components/team-tabs/team-tabs'

const TeamHome: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TeamTabs />
      </Grid>
    </Grid>
  )
}

export default TeamHome