import React from 'react'

import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import TeamHome from 'src/components/team-home/team-home'
import { usePrivateRoute } from 'src/utils/auth'

const IndexPage: React.FC = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Team" />
      <TeamHome />
    </Layout>
  )
}

export default IndexPage